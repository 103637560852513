import { createSvgIcon } from '@mui/material';

export const User = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 12.5C14.2091 12.5 16 10.7091 16 8.5C16 6.29086 14.2091 4.5 12 4.5C9.79086 4.5 8 6.29086 8 8.5C8 10.7091 9.79086 12.5 12 12.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 19.5C19 17.6435 18.2625 15.863 16.9497 14.5503C15.637 13.2375 13.8565 12.5 12 12.5C10.1435 12.5 8.36301 13.2375 7.05025 14.5503C5.7375 15.863 5 17.6435 5 19.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'User',
);
