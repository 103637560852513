import createClient from 'openapi-fetch';
import { MutationFunction, UseQueryOptions } from 'react-query';
import { paths } from 'src/types/en/notif';
import { defaultClientConfig } from '../clientConfig';
import { ErrorResponse } from '../errorutils';

export const { GET, POST, PATCH } = createClient<paths>(defaultClientConfig);

export const notifQueryKeygen = <
  URL extends Parameters<typeof GET>[0],
  INIT extends Parameters<typeof GET<URL>>[1],
>(
  url: URL,
  init: INIT,
): UseQueryOptions<Awaited<ReturnType<typeof GET<URL>>>['data'], ErrorResponse> => {
  return {
    queryKey: [url, init],
  };
};

export const notifPatchKeygen =
  <URL extends Parameters<typeof PATCH>[0]>(
    url: URL,
  ): MutationFunction<
    Awaited<ReturnType<typeof PATCH<URL>>>['data'],
    Parameters<typeof PATCH<URL>>[1]
  > =>
  async (variables) => {
    return (await PATCH(url, variables as any)).data;
  };

export const notifPostKeygen =
  <URL extends Parameters<typeof POST>[0]>(
    url: URL,
  ): MutationFunction<Awaited<ReturnType<typeof POST<URL>>>, Parameters<typeof POST<URL>>[1]> =>
  async (variables) => {
    return await POST(url, variables as any);
  };
