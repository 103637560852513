import { t } from '@lingui/macro';
import { Button, Dialog, Typography, alpha } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { notCompletedAuthModalStore } from 'src/core/stores/notCompletedAuthModalStore';
import { makeStyles } from 'tss-react/mui';
import { useStore } from 'zustand';
import { UserInfo } from '../icons/UserInfo';

export const NotCompletedAuthModal = () => {
  const state = useStore(notCompletedAuthModalStore);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const handleClick = () => {
    state.onClose();
    navigate('/authentication');
  };

  return (
    <Dialog
      open={state.open}
      onClose={state.onClose}
      classes={{ paper: classes.paper }}
      PaperProps={{ elevation: 1 }}
    >
      <div className={classes.content}>
        <UserInfo />
        <Typography
          variant="h3"
          mt={3}
          textAlign="center"
        >{t`Authentication is not completed`}</Typography>
        <Typography variant="body2" textAlign="center" mt={3}>
          {state.modalProps?.description}
        </Typography>
      </div>
      <div className={classes.action}>
        <Button variant="contained" fullWidth onClick={handleClick}>
          {t`Authenticate now`}
        </Button>
      </div>
    </Dialog>
  );
};
const useStyles = makeStyles()((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    borderBottom: `1.5px solid ${theme.palette.neutral.lightBlue}`,
  },
  errorIcon: {
    fontSize: 100,
    color: theme.palette.error.main,
  },

  description: {
    marginTop: theme.spacing(1),
    maxWidth: 300,
    textAlign: 'center',
    '& em': {
      ...theme.typography.body3,
      fontStyle: 'normal',
    },
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    gap: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(1),
    border: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    width: 390,
    borderRadius: 16,
    boxShadow: `0px 16px 32px 0px ${alpha(theme.palette.neutral.dark, 0.04)}`,
  },
}));
