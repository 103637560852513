export const UserInfo = () => (
  <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M96 48C96 61.4173 90.5089 73.5118 81.7041 82.2047C73.0888 90.8032 61.1598 96 48 96C34.9349 96 23.0059 90.7087 14.2959 82.2047C5.49112 73.5118 0 61.4173 0 48C0 21.4488 21.4911 0 48 0C74.5089 0 96 21.5433 96 48Z"
      fill="#E7EEF9"
    />
    <mask
      id="mask0_4248_1665"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="96"
      height="96"
    >
      <path
        d="M96 48C96 61.4173 90.5089 73.5118 81.7041 82.2047C73.0888 90.8032 61.1598 96 48 96C34.9349 96 23.0059 90.7087 14.2959 82.2047C5.49112 73.5118 0 61.4173 0 48C0 21.4488 21.4911 0 48 0C74.5089 0 96 21.5433 96 48Z"
        fill="#E7EEF9"
      />
    </mask>
    <g mask="url(#mask0_4248_1665)">
      <path
        d="M78.7202 23.04C80.841 23.04 82.5602 24.7592 82.5602 26.88V84.461C73.726 93.4904 61.4941 99.84 48.0002 99.84C34.6033 99.84 22.3714 93.3831 13.4402 84.4529V26.88C13.4402 24.7592 15.1594 23.04 17.2802 23.04H78.7202Z"
        fill="url(#paint0_linear_4248_1665)"
      />
      <path
        d="M48 56C54.6274 56 60 50.6274 60 44C60 37.3726 54.6274 32 48 32C41.3726 32 36 37.3726 36 44C36 50.6274 41.3726 56 48 56Z"
        fill="#545F7E"
      />
      <path
        d="M48 45C50.2091 45 52 43.2091 52 41C52 38.7909 50.2091 37 48 37C45.7909 37 44 38.7909 44 41C44 43.2091 45.7909 45 48 45Z"
        fill="#BCF449"
      />
      <path
        d="M56 52.9261C53.9818 54.8207 51.1437 56 48 56C44.8563 56 42.0182 54.8207 40 52.9261C41.1144 50.064 44.275 48 48 48C51.7249 48 54.8856 50.064 56 52.9261Z"
        fill="#BCF449"
      />
    </g>
    <path
      d="M61.4402 65.28H34.5602C33.4998 65.28 32.6402 66.1396 32.6402 67.2C32.6402 68.2604 33.4998 69.12 34.5602 69.12H61.4402C62.5006 69.12 63.3602 68.2604 63.3602 67.2C63.3602 66.1396 62.5006 65.28 61.4402 65.28Z"
      fill="#545F7E"
    />
    <path
      d="M61.4402 72.9599H34.5602C33.4998 72.9599 32.6402 73.8195 32.6402 74.8799C32.6402 75.9403 33.4998 76.7999 34.5602 76.7999H61.4402C62.5006 76.7999 63.3602 75.9403 63.3602 74.8799C63.3602 73.8195 62.5006 72.9599 61.4402 72.9599Z"
      fill="#545F7E"
    />
    <path
      d="M53.7602 80.6399H42.2402C41.1798 80.6399 40.3202 81.4996 40.3202 82.5599C40.3202 83.6203 41.1798 84.4799 42.2402 84.4799H53.7602C54.8206 84.4799 55.6802 83.6203 55.6802 82.5599C55.6802 81.4996 54.8206 80.6399 53.7602 80.6399Z"
      fill="#545F7E"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4248_1665"
        x1="13.5474"
        y1="23.2226"
        x2="84.2342"
        y2="98.1606"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#001A70" />
        <stop offset="1" stopColor="#0B1226" />
      </linearGradient>
    </defs>
  </svg>
);
