import { useState } from 'react';

export const usePopUp = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return { open, anchorEl, onClick, onClose };
};
