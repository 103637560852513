import { t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { EmptyState } from 'src/shared/icons/EmptyState';
import { makeStyles } from 'tss-react/mui';

type PropsType = {
  renderCaption?: boolean;
  caption?: string;
};
export const Empty = (props: PropsType) => {
  const { classes } = useStyles();
  const { caption = t`No items found`, renderCaption = true } = props;
  return (
    <div className={classes.root}>
      <EmptyState />
      {renderCaption && (
        <Typography variant="h3" mt={3}>
          {caption}
        </Typography>
      )}
    </div>
  );
};
const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
