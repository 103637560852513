import createClient from 'openapi-fetch';
import { getAppBaseUrl } from 'src/locales/utils';

type ClientConfigType = Parameters<typeof createClient>[0];

export const globalUserToken: { value: string | undefined; webSocketCode: string | undefined } = {
  value: undefined,
  webSocketCode: undefined,
};

export const defaultClientConfig: ClientConfigType = {
  baseUrl: getAppBaseUrl(),
  headers: {
    get Authorization() {
      return globalUserToken.value ? `Bearer ${globalUserToken.value}` : '';
    },
  },
};
