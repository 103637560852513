import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { CssBaseline, GlobalStyles } from '@mui/material';
import 'leaflet/dist/leaflet.css';
import { QueryClientProvider } from 'react-query';
import { RouterProvider } from 'react-router-dom';
import { router } from 'src/core/router/router';
import { globalStyles } from 'src/core/theme/GlobalStyles';
import { RTLProvider } from 'src/core/theme/RTLProvider';
import { queryClientConfig } from './core/query/queryClientConfig';
import { LTRProvider } from './core/theme/LTRProvider';
import { getAppDirection } from './core/theme/getAppDirection';
import { PageHeader } from './shared/components/PageHeader';

export function App() {
  const BidiTheme = getAppDirection() === 'ltr' ? LTRProvider : RTLProvider;
  document.body.dir = getAppDirection();
  return (
    //@ts-ignore
    <I18nProvider i18n={i18n}>
      <BidiTheme>
        <PageHeader />
        <CssBaseline />
        <GlobalStyles styles={globalStyles} />
        <QueryClientProvider client={queryClientConfig}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </BidiTheme>
    </I18nProvider>
  );
}
