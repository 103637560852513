import { createSvgIcon } from '@mui/material';

export const ArrowRight = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.46967 16.5303C9.76256 16.8232 10.2374 16.8232 10.5303 16.5303L14.5303 12.5303C14.8232 12.2374 14.8232 11.7626 14.5303 11.4697L10.5303 7.46967C10.2374 7.17678 9.76256 7.17678 9.46967 7.46967C9.17678 7.76256 9.17678 8.23744 9.46967 8.53033L11.5 12L9.46967 15.4697C9.17678 15.7626 9.17678 16.2374 9.46967 16.5303Z"
      fill="currentColor"
    />
  </svg>,
  'ArrowRight',
);
