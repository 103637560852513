import { Button, Chip, Popover } from '@mui/material';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { notifQueryKeygen as enNotifQueryKeygen } from 'src/core/query/en/notifClient';
import { usePopUp } from 'src/shared/hooks/usePopUp';
import { Notification as NotificationIcon } from 'src/shared/icons/Notification';
import { makeStyles } from 'tss-react/mui';
import { NotifPopUp } from './components/NotifPopUp';
import { NOTIF_INTERVAL_TIME } from './constants';

export const Notification = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { anchorEl, onClick: onClickPopUp, onClose: onClosePopUp, open: openPopUp } = usePopUp();
  const onShowAllClick = () => {
    navigate('/notifications/all');
    onClosePopUp();
  };

  const onShowSingleClick = (id: number) => {
    navigate(`/notifications/${id}`);
    onClosePopUp();
  };

  const { data: latestNotifData, refetch } = useQuery({
    ...enNotifQueryKeygen('/api/notif/latest-notification/', {
      params: { header: {} },
    }),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, NOTIF_INTERVAL_TIME);
    return () => clearInterval(interval);
  }, [refetch]);

  return (
    <div className={classes.notificationDiv}>
      <Button size="large" onClick={onClickPopUp} className={classes.notificationButton}>
        <NotificationIcon />
        <Chip
          size="small"
          label={latestNotifData?.unread_messages_count ?? '-'}
          className={classes.chip}
        />
      </Button>
      <Popover
        open={openPopUp}
        anchorEl={anchorEl}
        onClose={onClosePopUp}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ m: 1 }}
      >
        <NotifPopUp
          onClose={onClosePopUp}
          onShowAllClick={onShowAllClick}
          onShowSingleClick={onShowSingleClick}
          notifications={latestNotifData?.notifications}
        />
      </Popover>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  notificationDiv: {
    boxShadow: '0px 8px 24px 0px rgba(11, 18, 38, 0.04)',
    background: theme.palette.neutral.white,
    borderRadius: theme.shape.borderRadius,
  },
  notificationButton: {
    color: theme.palette.neutral.dark,
    paddingLeft: 14,
    paddingRight: 14,
  },
  chip: {
    background: theme.palette.primary.main,
  },
}));
