import createCache from '@emotion/cache';
import { CacheProvider, ThemeProvider } from '@emotion/react';
import React from 'react';
import rtlPlugin from 'stylis-plugin-rtl';
import { TssCacheProvider } from 'tss-react';
import { rtlTheme } from './theme';

const tssCacheRtl = createCache({
  key: 'tss-rtl',
  stylisPlugins: [rtlPlugin],
});

const muiCacheRtl = createCache({
  key: 'mui-rtl',
  prepend: true,
  stylisPlugins: [rtlPlugin],
});

interface OwnProps {}

type Props = React.PropsWithChildren<OwnProps>;

export function RTLProvider(props: Props) {
  return (
    <CacheProvider value={muiCacheRtl}>
      <TssCacheProvider value={tssCacheRtl}>
        <ThemeProvider theme={rtlTheme}>{props.children}</ThemeProvider>
      </TssCacheProvider>
    </CacheProvider>
  );
}
