import { t } from '@lingui/macro';
import { CircularProgress, Typography } from '@mui/material';
import { tss } from 'tss-react/mui';

type OwnProps = {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
};

export const FullScreenLoading = (props: OwnProps) => {
  const { classes } = useStyles({
    classesOverrides: props.classes,
  });
  return (
    <div className={classes.root}>
      <CircularProgress color="secondary" />
      <Typography>{t`Fetching information...`}</Typography>
    </div>
  );
};
const useStyles = tss.create(({ theme }) => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
