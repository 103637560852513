import { t } from '@lingui/macro';
import { Toolbar, ToolbarProps, Typography } from '@mui/material';
import { getAppLocale } from 'src/locales/utils';
import { makeStyles } from 'tss-react/mui';
import { redirectToLanding } from 'src/shared/utils/redirects';

export const Logo = (props: ToolbarProps) => {
  const { classes } = useStyles();
  return (
    <Toolbar className={classes.root} onClick={redirectToLanding} {...props}>
      <img
        src={getAppLocale() === 'en' ? '/tokenisionLogo.png' : '/metrilandLogo.png'}
        alt="Logo"
        className={classes.logo}
      />
      <Typography sx={{ marginLeft: 2 }} variant="h2" color="inherit" component="div">
        {t`Metri Land`}
      </Typography>
    </Toolbar>
  );
};

const useStyles = makeStyles()(() => ({
  root: {
    cursor: 'pointer',
  },
  logo: {
    width: 32,
    height: 32,
  },
}));
