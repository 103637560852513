export const EmptyState = () => (
  <svg
    width="133"
    height="113"
    viewBox="0 0 133 113"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M66 96C92.5089 96 114 74.5512 114 48C114 21.4488 92.4142 0 66 0C39.4911 0 18 21.4488 18 48C18 74.5512 39.4911 96 66 96Z"
      fill="#E7EEF9"
    />
    <g filter="url(#filter0_d_189_9085)">
      <path
        d="M111.738 22.7875V78.3822C111.738 80.0133 110.376 81.3726 108.742 81.3726H24.096C22.4617 81.4406 21.0997 80.0813 21.0997 78.4501V22.7875C21.0997 21.0884 22.4617 19.7971 24.096 19.7971H108.742C110.444 19.7971 111.738 21.1564 111.738 22.7875Z"
        fill="url(#paint0_linear_189_9085)"
      />
    </g>
    <path
      d="M111.738 22.7875V27.0693H21.0997V22.7875C21.0997 21.0884 22.4617 19.7971 24.096 19.7971H108.742C110.444 19.7971 111.738 21.1564 111.738 22.7875Z"
      fill="url(#paint1_linear_189_9085)"
    />
    <path
      d="M53.923 48.6137C55.3146 48.6137 56.4426 47.4879 56.4426 46.0991C56.4426 44.7103 55.3146 43.5844 53.923 43.5844C52.5315 43.5844 51.4034 44.7103 51.4034 46.0991C51.4034 47.4879 52.5315 48.6137 53.923 48.6137Z"
      fill="#989FB7"
    />
    <path
      d="M78.9831 48.6137C80.3747 48.6137 81.5028 47.4879 81.5028 46.0991C81.5028 44.7103 80.3747 43.5844 78.9831 43.5844C77.5916 43.5844 76.4635 44.7103 76.4635 46.0991C76.4635 47.4879 77.5916 48.6137 78.9831 48.6137Z"
      fill="#989FB7"
    />
    <path
      d="M70.539 65.1289H62.6396C61.4138 65.1289 60.3923 64.1094 60.3923 62.8861C60.3923 61.6627 61.4138 60.6433 62.6396 60.6433H70.4709C71.6966 60.6433 72.7181 61.6627 72.7181 62.8861C72.7862 64.1094 71.7647 65.1289 70.539 65.1289Z"
      fill="#989FB7"
    />
    <path
      d="M25.1175 24.6903C25.6816 24.6903 26.139 24.2338 26.139 23.6708C26.139 23.1078 25.6816 22.6513 25.1175 22.6513C24.5533 22.6513 24.096 23.1078 24.096 23.6708C24.096 24.2338 24.5533 24.6903 25.1175 24.6903Z"
      fill="white"
    />
    <path
      d="M28.3181 24.6903C28.8822 24.6903 29.3396 24.2338 29.3396 23.6708C29.3396 23.1078 28.8822 22.6513 28.3181 22.6513C27.754 22.6513 27.2966 23.1078 27.2966 23.6708C27.2966 24.2338 27.754 24.6903 28.3181 24.6903Z"
      fill="white"
    />
    <path
      d="M31.5868 24.6903C32.151 24.6903 32.6083 24.2338 32.6083 23.6708C32.6083 23.1078 32.151 22.6513 31.5868 22.6513C31.0227 22.6513 30.5654 23.1078 30.5654 23.6708C30.5654 24.2338 31.0227 24.6903 31.5868 24.6903Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d_189_9085"
        x="0.271288"
        y="9.38291"
        width="132.295"
        height="103.235"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10.4142" />
        <feGaussianBlur stdDeviation="10.4142" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.619608 0 0 0 0 0.647059 0 0 0 0 0.737255 0 0 0 0.32 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_189_9085" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_189_9085"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_189_9085"
        x1="66.3895"
        y1="18.3728"
        x2="66.3895"
        y2="82.0389"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.9964" stopColor="#F8FAFD" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_189_9085"
        x1="108.143"
        y1="19.7971"
        x2="24.0209"
        y2="23.4475"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B0BACC" />
        <stop offset="1" stopColor="#969EAE" />
      </linearGradient>
    </defs>
  </svg>
);
