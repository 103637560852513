import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router';
import { useIsLogin, useMedia } from 'src/shared/hooks';
import { NotCompletedAuthModal } from '../components/NotCompletedAuthModal';
import { AppBar } from './AppBar';
import { Drawer } from './Drawer';
import { Header } from './Header';
import { LgHeaderHeight, SmHeaderHeight, drawerWidth } from './constants';

export const DashboardLayout = () => {
  const isLg = useMedia('lg');
  const { isLogin } = useIsLogin();
  if (!isLogin) return <div />;
  return (
    <GridContainer>
      <DrawerRegion>{isLg ? <Drawer /> : <AppBar />}</DrawerRegion>
      <HeaderRegion>
        <Header />
      </HeaderRegion>
      <ContentRegion id="content-region">
        <Outlet />
      </ContentRegion>
      <NotCompletedAuthModal />
    </GridContainer>
  );
};

export const GridContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  width: '100%',
  height: '100vh',
  gridTemplateRows: `[drawer-start] 120px [drawer-end] ${SmHeaderHeight}px [header-end] 1fr [end]`,
  gridTemplateColumns: '1fr',
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: `[drawer-start-lg] ${drawerWidth}px [drawer-end-lg] 1fr [end]`,
    gridTemplateRows: `[header-start-lg] ${LgHeaderHeight}px [header-end-lg] 1fr [end]`,
  },
}));

export const DrawerRegion = styled('div')(({ theme }) => ({
  gridRow: 'drawer-start / drawer-end',
  [theme.breakpoints.up('lg')]: {
    gridColumn: 'drawer-start-lg / drawer-end-lg',
    gridRow: 'header-start-lg / end',
  },
}));

export const HeaderRegion = styled('div')(({ theme }) => ({
  gridRow: 'drawer-end / header-end',
  [theme.breakpoints.up('lg')]: {
    gridColumn: 'drawer-end-lg / end',
    gridRow: 'header-start-lg / header-end-lg',
  },
}));

export const ContentRegion = styled('div')(({ theme }) => ({
  overflow: 'overlay',
  gridRow: 'header-end / end',
  [theme.breakpoints.up('lg')]: {
    gridColumn: 'drawer-end-lg / end',
    gridRow: 'header-end-lg / end',
  },
}));
