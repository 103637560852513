import { IconButton, Popover } from '@mui/material';
import { usePopUp } from 'src/shared/hooks/usePopUp';
import { More } from 'src/shared/icons/More';
import { makeStyles } from 'tss-react/mui';
import { ActionsPopUp } from './ActionsPopUp';

type PropsType = {
  role: string | null;
  username: string | undefined;
};
export const ActionsSection = (props: PropsType) => {
  const { classes } = useStyles();

  const { anchorEl, onClick: onClickPopUp, onClose: onClosePopUp, open: openPopUp } = usePopUp();

  return (
    <div className={classes.notificationDiv}>
      <IconButton onClick={onClickPopUp} sx={{ marginLeft: 'auto' }}>
        <More />
      </IconButton>
      <Popover
        open={openPopUp}
        anchorEl={anchorEl}
        onClose={onClosePopUp}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ m: 1 }}
      >
        <ActionsPopUp onClose={onClosePopUp} role={props.role} username={props.username} />
      </Popover>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  notificationDiv: {
    boxShadow: '0px 8px 24px 0px rgba(11, 18, 38, 0.04)',
    background: theme.palette.neutral.white,
    borderRadius: theme.shape.borderRadius,
  },
  notificationButton: {
    color: theme.palette.neutral.dark,
    paddingLeft: 14,
    paddingRight: 14,
  },
  chip: {
    background: theme.palette.primary.main,
  },
}));
