import { format } from 'date-fns';
import { format as jalaaliFormat } from 'date-fns-jalali';
import { getAppLocale } from 'src/locales/utils';

export const formatDate = (
  date?: string | number | Date | null,
  locale: 'fa' | 'en' = getAppLocale(),
) =>
  locale === 'fa'
    ? jalaaliFormat(date ? new Date(date) : new Date(), 'yyyy/MM/dd')
    : format(date ? new Date(date) : new Date(), 'yyyy-MM-dd');

export const formatDateTime = (
  date?: string | number | Date | null,
  locale: 'fa' | 'en' = getAppLocale(),
) =>
  locale === 'fa'
    ? jalaaliFormat(date ? new Date(date) : new Date(), 'HH:mm - yyyy/MM/dd')
    : format(date ? new Date(date) : new Date(), 'HH:mm - yyyy-MM-dd');

export const formatChatDate = (
  date?: string | number | Date | null,
  locale: 'fa' | 'en' = getAppLocale(),
) =>
  locale === 'fa'
    ? jalaaliFormat(date ? new Date(date) : new Date(), "dd MMMM yyyy 'ساعت' HH:mm")
    : format(date ? new Date(date) : new Date(), "dd MMMM yyyy 'at' HH:mm");
