import { createSvgIcon } from '@mui/material';

export const Notification = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.66667 9.2C6.66667 7.82087 7.22857 6.49823 8.22876 5.52304C9.22896 4.54786 10.5855 4 12 4C13.4145 4 14.771 4.54786 15.7712 5.52304C16.7714 6.49823 17.3333 7.82087 17.3333 9.2C17.3333 12.5711 18.1567 14.6041 18.8884 15.7453C19.1571 16.1643 18.811 17 18.3133 17H5.68668C5.18897 17 4.84292 16.1643 5.11157 15.7453C5.84328 14.6041 6.66667 12.5711 6.66667 9.2Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10 20H14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>,
  'Notification',
);
