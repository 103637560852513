import { createSvgIcon } from '@mui/material';

export const Logout = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M9 20H5.66667C5.22464 20 4.80072 19.8127 4.48816 19.4793C4.17559 19.1459 4 18.6937 4 18.2222V5.77778C4 5.30628 4.17559 4.8541 4.48816 4.5207C4.80072 4.1873 5.22464 4 5.66667 4H9"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 16L20 12L16 8"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 12H9"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'Logout',
);
