import { createSvgIcon } from '@mui/material';

export const Chat = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 20L5.68868 14.934C4.88623 13.3295 4.68091 11.4919 5.10947 9.74989C5.53802 8.0079 6.57249 6.47527 8.02774 5.42626C9.48298 4.37725 11.264 3.88033 13.0522 4.02442C14.8403 4.16851 16.5188 4.94421 17.7873 6.21271C19.0558 7.48121 19.8315 9.15971 19.9756 10.9478C20.1197 12.736 19.6227 14.517 18.5737 15.9723C17.5247 17.4275 15.9921 18.462 14.2501 18.8905C12.5081 19.3191 10.6705 19.1138 9.06605 18.3113L4 20Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'Chat',
);
