import { Interpolation } from '@emotion/react';
import { Theme, alpha } from '@mui/material';

export const globalStyles: Interpolation<Theme> = (theme) => ({
  '::-webkit-scrollbar-thumb': {
    '&:hover': {
      background: theme.palette.neutral.lightGrey,
    },
    background: alpha(theme.palette.neutral.darkGrey, 0.5),
    borderRadius: 5,
  },
  '::-webkit-scrollbar': {
    width: 5,
    height: 5,
  },
});
