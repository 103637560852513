import { createSvgIcon } from '@mui/material';

export const Basket = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.2 5H4.8C3.80589 5 3 5.71634 3 6.6V7.4C3 8.28366 3.80589 9 4.8 9H19.2C20.1941 9 21 8.28366 21 7.4V6.6C21 5.71634 20.1941 5 19.2 5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 9V17.1818C5 17.664 5.18437 18.1265 5.51256 18.4675C5.84075 18.8084 6.28587 19 6.75 19H17.25C17.7141 19 18.1592 18.8084 18.4874 18.4675C18.8156 18.1265 19 17.664 19 17.1818V9"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10 13H14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>,
  'Basket',
);
