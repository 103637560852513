import createCache from '@emotion/cache';
import { CacheProvider, ThemeProvider } from '@emotion/react';
import React from 'react';
import { TssCacheProvider } from 'tss-react';
import { ltrTheme } from './theme';

const tssCacheRtl = createCache({
  key: 'tss-ltr',
});

const muiCacheRtl = createCache({
  key: 'mui-ltr',
  prepend: true,
});

interface OwnProps {}

type Props = React.PropsWithChildren<OwnProps>;

export function LTRProvider(props: Props) {
  return (
    <CacheProvider value={muiCacheRtl}>
      <TssCacheProvider value={tssCacheRtl}>
        <ThemeProvider theme={ltrTheme}>{props.children}</ThemeProvider>
      </TssCacheProvider>
    </CacheProvider>
  );
}
