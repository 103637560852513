import { buttonClasses } from '@mui/material';
import { ThemePart } from 'src/core/theme';

export const themeButtonPart: ThemePart<'MuiButton'> = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      ...(ownerState.size === 'small' && {
        padding: '6px 24px',
      }),
      ...(ownerState.size === 'medium' && {
        padding: '8px 24px',
      }),
      ...(ownerState.size === 'large' && {
        padding: '12px 32px',
      }),
      ...(ownerState.variant === 'contained' && {
        [`&.${buttonClasses.disabled}`]: {
          background: theme.palette.neutral.lightBlue,
        },
      }),
      ...(ownerState.variant === 'outlined' && {
        borderWidth: '1.5px',
        [`&.${buttonClasses.disabled}`]: {
          borderColor: theme.palette.neutral.lightBlue,
        },
      }),

      //outlined grey variant
      ...(ownerState.color === 'coolGrey' &&
        ownerState.variant === 'outlined' && {
          borderColor: theme.palette.coolGrey.main,
          color: theme.palette.common.black,
          '&:hover': {
            borderColor: theme.palette.coolGrey.main,
            background: theme.palette.coolGrey.main,
          },
        }),
    }),
  },
};
