import { createSvgIcon } from '@mui/material';

export const Market = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.9375 5.25V10.875"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.34375 10.875H5.53125C4.7546 10.875 4.125 11.4626 4.125 12.1875V17.4375C4.125 18.1624 4.7546 18.75 5.53125 18.75H8.34375C9.1204 18.75 9.75 18.1624 9.75 17.4375V12.1875C9.75 11.4626 9.1204 10.875 8.34375 10.875Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.9375 18.75V21" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.0625 3V5.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M18.4688 5.25H15.6562C14.8796 5.25 14.25 5.81664 14.25 6.51562V14.1094C14.25 14.8084 14.8796 15.375 15.6562 15.375H18.4688C19.2454 15.375 19.875 14.8084 19.875 14.1094V6.51562C19.875 5.81664 19.2454 5.25 18.4688 5.25Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.0625 15.375V19.875"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'Market',
);
