import { createSvgIcon } from '@mui/material';

export const CreditCard = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.4 6H5.6C4.71634 6 4 6.76751 4 7.71429V16.2857C4 17.2325 4.71634 18 5.6 18H18.4C19.2837 18 20 17.2325 20 16.2857V7.71429C20 6.76751 19.2837 6 18.4 6Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 10H20"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'CreditCard',
);
