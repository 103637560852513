import * as React from 'react';
import { useQuery } from 'react-query';
import { USER_AUTH_TOKEN, USER_ROLE } from 'src/core/domain/auth/localStorageKeys';
import { isAuthError } from 'src/core/query/errorutils';
import { sharedAuthQueryKeygen } from 'src/core/query/sharedKeygen';
import { redirectToRemoveToken } from 'src/shared/utils/redirects';
import { globalUserToken } from 'src/core/query/clientConfig';

export const useIsLogin = (token?: string, role?: string) => {
  const [isLogin, setIsLogin] = React.useState(false);
  const innerToken = token || localStorage.getItem(USER_AUTH_TOKEN);
  const innerRole = role || localStorage.getItem(USER_ROLE);
  const { data, error } = useQuery({
    ...sharedAuthQueryKeygen('/api/v1/auth/user/token-validation', {
      params: {
        header: {
          Authorization: `Bearer ${innerToken}`,
        },
      },
    }),
    enabled: !!innerToken && !!innerRole,
    retry: false,
  });

  React.useEffect(() => {
    if (!innerToken && !innerRole) redirectToRemoveToken();
    if (error && isAuthError(error.response)) {
      localStorage.removeItem(USER_AUTH_TOKEN);
      localStorage.removeItem(USER_ROLE);
      redirectToRemoveToken();
    } else if (innerRole && innerToken && data) {
      localStorage.setItem(USER_AUTH_TOKEN, innerToken);
      localStorage.setItem(USER_ROLE, innerRole);
      globalUserToken.value = innerToken;
      setIsLogin(true);
    }
  }, [data, error, innerRole, innerToken]);

  return { isLogin };
};
