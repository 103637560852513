import { alpha, outlinedInputClasses } from '@mui/material';
import { getColorFromThemeWithColorProps, type ThemePart } from 'src/core/theme';

export const themeTextFieldPart: ThemePart<'MuiOutlinedInput'> = {
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      borderRadius: 10,
      background: theme.palette.neutral.lightExtraBlue,
      borderColor: theme.palette.neutral.lightBlue,
      [`&.Mui-focused`]: {
        boxShadow: `0px 0px 16px 0px ${alpha(
          getColorFromThemeWithColorProps(theme, ownerState),
          0.14,
        )}`,
        background: theme.palette.neutral.white,
      },
      [`&.Mui-focused.Mui-error`]: {
        boxShadow: `0px 0px 16px 0px ${alpha(
          getColorFromThemeWithColorProps(theme, { color: 'error' }),
          0.14,
        )}`,
        background: theme.palette.neutral.white,
      },
      [`&.${outlinedInputClasses.disabled}`]: {
        background: theme.palette.neutral.lightBlue,
        color: theme.palette.neutral.lightGrey,
      },
    }),
  },
};
