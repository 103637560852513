import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  InputProps,
  OutlinedInput,
  OutlinedInputProps,
  formLabelClasses,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export interface TextFieldProps
  extends Pick<InputProps, 'fullWidth' | 'disabled' | 'size' | 'startAdornment'> {
  label?: string;
  helperText?: string;
  formLabelProps?: FormLabelProps;
  formControlProps?: FormControlProps;
  formHelperTextProps?: FormHelperTextProps;
}

export const TextField = (props: OutlinedInputProps & TextFieldProps) => {
  const {
    label,
    disabled,
    helperText,
    error,
    formHelperTextProps,
    fullWidth,
    formLabelProps,
    formControlProps,
    color = 'secondary',
    required,
    ...rest
  } = props;
  const { classes } = useStyles();
  return (
    <FormControl
      required={required}
      disabled={disabled}
      fullWidth={fullWidth}
      {...formControlProps}
    >
      {label && (
        <FormLabel classes={{ root: classes.formLabel }} sx={{ mb: 1, ...formLabelProps?.sx }}>
          {label}
        </FormLabel>
      )}
      <OutlinedInput color={color} error={error} {...rest} />
      {helperText && (
        <FormHelperText error={error} {...formHelperTextProps}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
const useStyles = makeStyles()((theme) => ({
  formLabel: {
    color: theme.palette.common.black,
    [`&.${formLabelClasses.focused}`]: {
      color: theme.palette.common.black,
    },
  },
}));
