import { t } from '@lingui/macro';
import { Basket } from 'src/shared/icons/Basket';
import { Chat } from 'src/shared/icons/Chat';
import { Logout } from 'src/shared/icons/Logout';
import { Market } from 'src/shared/icons/Market';
import { Opportunity } from 'src/shared/icons/Opportunity';
import { Wallet } from 'src/shared/icons/Wallet';
import { MenuItem } from './types';

export const menuItems: MenuItem[] = [
  {
    icon: <Opportunity />,
    title: t`Investment Opportunities`,
    to: '/investment-opportunities',
  },
  {
    icon: <Basket />,
    title: t`My Investments Basket`,
    to: '/my-investments-basket',
  },
  {
    icon: <Wallet />,
    title: t`Wallet`,
    to: '/wallet',
  },
  {
    icon: <Market />,
    title: t`Market`,
    children: [
      {
        title: t`Initial Supply`,
        to: '/market/initial-supply',
      },
      { title: t`Fast Buying And Selling`, to: '/market/otc' },
    ],
  },
  {
    icon: <Chat />,
    title: t`Support`,
    to: '/support',
  },
  {
    icon: <Logout />,
    title: t`Logout`,
  },
];
export const breadCrumbs = menuItems.flatMap((item) => item.children || item);
