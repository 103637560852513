import { createStore } from 'zustand/vanilla';

interface ModalProps {
  title?: string;
  description?: string;
}

interface GlobalModalStore {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  modalProps?: ModalProps;
  onSetModalProps: (props: ModalProps) => void;
}
export const notCompletedAuthModalStore = createStore<GlobalModalStore>((set) => ({
  open: false,
  modalProps: { title: '', description: '' },
  onClose: () => set({ open: false }),
  onOpen: () => set({ open: true }),
  onSetModalProps: (props: ModalProps) => set({ modalProps: props }),
}));
