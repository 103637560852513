import { i18n } from '@lingui/core';
import { messages as enMessages } from 'src/locales/en/messages';
import { messages as faMessages } from 'src/locales/fa/messages';

const locale = import.meta.env.VITE_APP_LOCALE;
const messages = locale === 'fa' ? faMessages : enMessages;

i18n.load(locale, messages);
i18n.activate(locale);

export { i18n };
