import { t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router';
import { breadCrumbs } from 'src/core/router';
import { DesktopUserInfoBox } from 'src/shared/components/UserInfoBox';
import { useMedia } from 'src/shared/hooks';
import { makeStyles } from 'tss-react/mui';
import { Notification } from '../components/Notification';

export const Header = () => {
  const { classes } = useStyles();
  const isMd = useMedia('md');
  const { pathname } = useLocation();
  const notInSidebarPages = [
    { to: '/authentication', title: t`Authentication` },
    { to: '/notifications/', title: t`Notifications` },
  ];
  return (
    <div className={classes.root}>
      {isMd && (
        <Typography className={classes.title} variant={isMd ? 'h2' : 'h3'} color="black">
          {!!pathname &&
            [...notInSidebarPages, ...breadCrumbs].find((breadcrumb) =>
              pathname.includes(breadcrumb.to || ''),
            )?.title}
        </Typography>
      )}
      <DesktopUserInfoBox />
      <Notification />
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    background: theme.palette.neutral.lightExtraBlue,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    gap: 6,
  },
  title: {
    flexGrow: 1,
  },
  chip: {
    background: theme.palette.primary.main,
  },
}));
