import * as React from 'react';
import { Empty } from '../Empty';
import { FullScreenLoading } from '../FullScreenLoading';

interface OwnProps {
  loading?: boolean;
  empty?: boolean;
  error?: boolean;
  emptyComponent?: React.ReactElement;
  renderCaption?: boolean;
  caption?: string;
}

export const ConditionalRender = (props: React.PropsWithChildren<OwnProps>) => {
  const { loading, empty, error, emptyComponent } = props;
  return loading ? (
    <FullScreenLoading />
  ) : empty ? (
    emptyComponent ?? <Empty renderCaption={props.renderCaption} caption={props.caption} />
  ) : error ? (
    <div></div>
  ) : (
    props.children
  );
};
