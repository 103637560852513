import { t } from '@lingui/macro';
import { Divider, IconButton, Typography, alpha } from '@mui/material';
import { Close } from 'src/shared/icons/Close';
import { Dot } from 'src/shared/icons/Dot';
import { formatDateTime } from 'src/shared/utils/date-utils';
import { components } from 'src/types/en/notif';
import { makeStyles } from 'tss-react/mui';
import { ConditionalRender } from '../../ConditionalRender';

type PropsType = {
  onClose: () => void;
  onShowAllClick: () => void;
  onShowSingleClick: (id: number) => void;
  notifications?: components['schemas']['NotificationList'][];
};
export const NotifPopUp = (props: PropsType) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h3">{t`Messages`}</Typography>
        <div className={classes.endHeader}>
          <Typography
            sx={{ cursor: 'pointer' }}
            onClick={props.onShowAllClick}
            color={'secondary'}
          >{t`Show all`}</Typography>
          <IconButton onClick={props.onClose}>
            <Close />
          </IconButton>
        </div>
      </div>
      <Divider />
      <div className={classes.notifList}>
        <ConditionalRender empty={!props.notifications}>
          {props.notifications?.map((notif, index) => (
            <>
              <div className={classes.row} onClick={() => props.onShowSingleClick(notif.id)}>
                {
                  // @ts-ignore
                  <Dot color={notif.is_read ? 'coolGrey' : 'secondary'} />
                }
                <div className={classes.notif}>
                  <Typography variant="mediumH4">{notif.subject}</Typography>
                  <Typography variant="body1" color={'neutral.darkGrey'}>
                    {formatDateTime(notif.time)}
                  </Typography>
                </div>
              </div>
              {props.notifications && index !== props.notifications?.length - 1 && <Divider />}
            </>
          ))}
        </ConditionalRender>
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    width: 300,
    boxShadow: `8px 8px 8px 8px ${alpha(theme.palette.neutral.dark, 0.5)}`,
    border: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    background: theme.palette.neutral.white,
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
  },
  row: {
    display: 'flex',
    flexGrow: 1,
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
  },
  endHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  notifList: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(3),
  },
  notif: {
    minWidth: 0,
    flexGrow: 1,
    gap: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
}));
