import { t } from '@lingui/macro';
import { Avatar, Button, Chip, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { USER_ROLE } from 'src/core/domain/auth/localStorageKeys';
import { sharedAuthQueryKeygen } from 'src/core/query/sharedKeygen';
import { makeStyles } from 'tss-react/mui';
import { ArrowPrev } from '../BidiIcons/ArrowPrev';

export const MobileUserInfoBox = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { data: status } = useQuery({
    ...sharedAuthQueryKeygen('/api/v1/auth/user/investor/auth/status', {
      params: { header: {} },
    }),
    enabled: true,
  });
  const authenticationStep = status?.data?.account;
  const role = localStorage.getItem(USER_ROLE);

  const { data: personalInfo } = useQuery({
    ...sharedAuthQueryKeygen('/api/v1/auth/user/investor/info/personal', {
      params: { header: {} },
    }),
  });

  const handleRedirectToAuthentication = () => {
    navigate('/authentication');
  };

  const color =
    authenticationStep === 'active'
      ? 'success'
      : authenticationStep === 'rejected'
      ? 'warning'
      : 'secondary';

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <Avatar sx={{ width: 40 }} src={''} />
        <Typography variant="body1" color="black">
          {personalInfo?.data?.first_name ?? t`No name`}
        </Typography>
        {role && (
          <Chip
            size="small"
            label={role === 'Investor' ? t`Investor` : t`Broker`}
            classes={{ root: classes.chip }}
            variant="outlined"
          />
        )}
      </div>
      <Button
        variant="contained"
        color={color}
        className={classes.action}
        startIcon={<ArrowPrev />}
        onClick={handleRedirectToAuthentication}
      >
        {authenticationStep === 'active'
          ? t`Authenticated`
          : authenticationStep === 'pending'
          ? t`Authentication pending`
          : authenticationStep === 'rejected'
          ? t`Authentication rejected`
          : t`Authenticate now`}
      </Button>
    </div>
  );
};
const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: 100,
    background: theme.palette.neutral.white,
    borderRadius: theme.shape.borderRadius,
    padding: 4,
    position: 'relative',
  },
  action: {
    width: '100%',
    height: '100%',
    borderRadius: theme.shape.borderRadius,
    paddingTop: 55,
  },
  info: {
    width: '100%',
    height: 48,
    position: 'absolute',
    zIndex: 2,
    left: 0,
    top: 0,
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.common.white,
    display: 'flex',
    paddingLeft: 4,
    paddingRight: 4,
    alignItems: 'center',
    gap: 6,
  },
  chip: {
    background: theme.palette.neutral.lightBlue,
    color: theme.palette.secondary.dark,
  },
}));
